// Working out stuff using ems, because life.
@form-control-height: @content-font-size * 2;
@label-font-size: 14px;
@label-line-height: (4 / 3);

.form {
	margin-left: -@gutter;
	margin-right: -@gutter;

	.form-row-group {
		position: relative;
		margin: 0;
		padding: 0.75em 0;
		border: 0;

		+ .form-row-group {
			border-top: 1px solid @border;
		}
	}

	.form-row {
		padding: 0.75em 0;
		.clearfix();

		.row-header,
		.row-body {
			box-sizing: border-box;
			padding-left: @gutter;
			padding-right: @gutter;
		}

		.row-header {
		}

		.row-body {
			+ .row-body {
				padding-top: 0.75em;
			}
		}
	}

	.row-label {
		font-size: @label-font-size;
		line-height: @label-line-height;
	}
}

@media screen and (max-width: @screen-max-small) {
	.form {
		.form-row {
			.row-header {
				padding-bottom: 0.5em;
			}
		}
	}
}

@media screen and (min-width: @screen-min-medium) {
	.form {
		.form-row {
			.row-header {
				float: left;
				width: 1 / 4 * 100%;
			}

			.row-body {
				float: right;
				width: 3 / 4 * 100%;
			}
		}
	}

	.row-label {
		margin-top: unit((@form-control-height - (@label-font-size * @label-line-height)) / 2 / @label-font-size, em);
	}
}