.content {
	table {
		width: 100%;
		text-align: left;
		line-height: (4 / 3);

		caption {
			text-align: left;
		}

		p {
			margin: 0;
			
			+ p { margin-top: 0.75em; }
		}

		td, th {
			vertical-align: top;
			border-bottom: 1px solid;
			font-size: 0.8em;
			font-weight: normal;
			padding: 0.75em 0.25em;
		}

		th {
			font-size: 0.66em;
			font-weight: normal;
			text-transform: uppercase;
			white-space: nowrap;
		}
	}

	.table-wrapper {
		margin: 1.5em -@gutter;
		
		table {
			margin: 0;
			
			caption {
				margin-left: @gutter;
				margin-right: @gutter;
			}
		}
	}
}