#footer {
	.constraint {
		padding-top: (@gutter * 4);
	}
}

#footer-nav {

	ul {
		margin: 0;
		padding: (@gutter * 2) (@gutter * 2);
		text-align: center;
		font-size: 18px;
	}

	li {
		display: inline-block;

		+ li {
			&:before {
				content: "\00a\2022\00a\00a";
				opacity: 0.5;
			}
		}
	}

	a {
		border-bottom: 1px solid;
	}
}