#header {
	.constraint {
		margin-bottom: (@gutter * 2);
	}
}

#header-graphic {
	img {
		max-width: 100%;
		height: auto;
	}
}

#header-nav {
	position: relative;
	padding: 0.75em 0;
	text-align: center;
	z-index: 2;

	ul {
		margin: 0;
		padding: 0;

		li {
			list-style: none;
		}
	}

	> ul {
		margin: 0;
		padding: 0;
		font-size: 18px;

		> li {
			position: relative;
			display: inline-block;
			margin: unit((1 / 3), em) unit((2 / 3), em);
			line-height: 1;
			vertical-align: middle;

			a {
				position: relative;
				border-bottom: 2px solid;
				transition: color 500ms @ease-out-expo, border-color 500ms @ease-out-expo;

				&[href]:hover {
					transition: color 250ms @ease-out-expo, border-color 250ms @ease-out-expo;
				}
			}

			&.has-dropdown {
				> a {
					&:hover,
					&:active,
					&:focus {
						z-index: 3;
					}
				}
			}
		}
	}

	.dropdown {
		position: absolute;
		top: 100%;
		left: 50%;
		z-index: 1;

		.dropdown-list {
			position: relative;
			right: 50%;
			padding: 0.75em 0 0.25em 0; 
			font-size: 14px;
			font-weight: normal;
			line-height: 1.33333333;

			li {
				display: block;
				margin: 0.5em 1em;

				a {
					display: inline-block;
					max-width: 100%;
					.text-overflow();
					border-bottom-width: 1px;
				}
			}
		}

		&[data-closed] {
			display: none;
		}

		&.fade {
			opacity: 0;
			// Fade out
			transition: opacity 125ms @ease-out-expo;

			&.in {
				opacity: 1;
				transition: opacity 250ms @ease-out-expo;
			}
		}
	}

	&[data-uppercase] {
		> ul {
			> li {
				padding-top: 0.25em;
				padding-bottom: 0.25em;
				
				> a {
					font-size: 16px;
					font-weight: 700;
					letter-spacing: 0.0625em;
					text-transform: uppercase;

					html.oldie & {
						font-size: 15px;
					}
				}
			}
		}
	}
}

@media screen and (min-width: @screen-min-medium) {
}