.typography() {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		+ p {
			margin-top: -0.25em;
		}
	}

	a {
		border-bottom: 1px solid;
		transition: color 500ms @ease-out-expo, border-color 500ms @ease-out-expo;

		&:hover {
			transition: color 250ms @ease-out-expo, border-color 250ms @ease-out-expo;
		}
	}

	p {
		margin: 1.25em 0;

		> img {
			margin-top: 1em;
			margin-bottom: 1em;
		}
	}

	ul,
	ol,
	dl,
	blockquote,
	pre,
	img,
	iframe {
		margin-top: 1em;
		margin-bottom: 1em;
	}

	ul,
	ol {
		padding: 0; 

		ul,
		ol {
			margin-top: 0.5em;
			margin-bottom: 0.5em;
		}

		li {
			margin-top: 0.25em;
			margin-bottom: 0.25em;
			margin-left: 1.5em;
		}
	}

	ul li {
		list-style: square;
	}
	
	ol li {
		list-style: decimal;
	}

	dl {
		dt {
			margin-top: 0.5em;
			margin-bottom: 0.25em;
		}

		dd {
			margin-left: 1.5em;
		}
	}

	code {
		margin-left: 0.25em;
		margin-right: 0.25em;
	}

	pre {
		padding: 0.75em 1em;
		overflow: auto;
		word-break: normal;

		code {
			margin-left: 0;
			margin-right: 0;
			overflow-wrap: normal;
			white-space: pre-wrap;
		}
	}

	img {
		max-width: 100%;
		height: auto;
		
		&[align="left"] {
			clear: both;
			float: left;
			margin-right: @gutter * 4;
		}
		
		&[align="right"] {
			clear: both;
			float: right;
			margin-left: @gutter * 4;
		}
		
		&[align="center"],
		&[align="middle"] {
			clear: both;
			margin-left: auto;
			margin-right: auto;
		}
	}

	blockquote {
		margin-right: 0;
		margin-left: 0.75em;
		padding-left: 0.75em;
	}
}

a {
	text-decoration: none;
}

i, em {

}

b, strong {
	font-weight: normal;
}

pre, code {
	//font-family: @mono-font-stack;
}

code {
}

hr {
	margin: 3em 0;
}

h1,
h2,
h3,
h4,
h5,
h6, 
table caption {
	font-weight: normal;
}

h1 {
	margin: 1em 0 unit((2 / 3), em);
	font-size: 36px;
	line-height: 1.25;
	letter-spacing: unit((-0.5 / 32), em);
}

h2 {
	margin: 1.75em 0 0.5em;
	font-size: 24px;
	line-height: (4 / 3);
}

h3 {
	margin: 2em 0 unit((2 / 3), em);
	font-size: 21px;
	line-height: (4 / 3);
}

h4 {
	margin: 2em 0 1em;
	font-size: 18px;
	line-height: (4 / 3);
}

h5,
table caption {
	margin: 2em 0 1em;
	font-size: 16px;
	line-height: 1.5;
}

h6 {
	margin: 2.5em 0 1.5em;
	font-size: 14px;
	line-height: (4 / 3);
	letter-spacing: 0.15em;
	text-transform: uppercase;
}