.form-control {
	box-sizing: border-box;
	display: block;
	height: 2em;
	width: 100%;
	margin: 0;
	box-shadow: none;
	transition: border-color 333ms @ease-out-expo, box-shadow 333ms @ease-out-expo;
	outline: 0;

	// Disabled styles!
	input[disabled]& {
	}

	&:hover {
	}

	&:active,
	&:focus {
		transition: border-color 500ms @ease-out-expo, box-shadow 500ms @ease-out-expo;
	}
}

.radio-control,
.checkbox-control {
	display: table;
	line-height: (4 / 3);

	> input,
	> .control-label-body {
		display: table-cell;
	}

	> input {
		margin: 0;
		padding: 0;
	}

	> .control-label-body {
		width: 100%;
		padding-left: @gutter;
		user-select: none;
	}
}

.textarea-control {
	textarea {
		height: 12em;	
	}
}

.control-w-1-2 {
	width: 50%;
}

.control-w-1-3 {
	width: 1 / 3 * 100%;
}

.control-w-2-3 {
	width: 2 / 3 * 100%;
}		

.control-w-1-4 {
	width: 25%;
}

.control-w-5-6 {
	width: 5 / 6 * 100%;
}

.control-label {
}

@media screen and (max-width: @screen-max-small) {
	.control-w-1-4,
	.control-w-1-3,
	.control-w-1-2,
	.control-w-2-3,
	.control-w-5-6 {
		width: 100%;
	}
}