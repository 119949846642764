@image-base-url:				'../img/minisite/';

// Sprite Social
.s-s {
	background-image: url("@{image-base-url}sprite-social.png");
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: 40px 80px;

	// https://github.com/twbs/bootstrap/blob/master/less/mixins/image.less
	@media
	only screen and (-webkit-min-device-pixel-ratio: 2),
	only screen and (   min--moz-device-pixel-ratio: 2),
	only screen and (     -o-min-device-pixel-ratio: 2/1),
	only screen and (        min-device-pixel-ratio: 2),
	only screen and (                min-resolution: 192dpi),
	only screen and (                min-resolution: 2dppx) {
		background-image: url("@{image-base-url}sprite-social@2x.png");
	}
}