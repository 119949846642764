html {
	height: 100%;
	min-height: 400px;
	min-width: 320px;
}

body {
	position: relative;
	min-height: 100%;
	height: 100%;
	margin: 0;
	font-size: 100%;
	line-height: 1.5;
	cursor: default;
}

figure {
	margin: 0;
}

.width-limit() {
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	max-width: @max-content-width;
}

.liquid-positioning {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

#wrapper {
	display: table;
	width: 100%;
	height: 100%;

	> * {
		display: table-row;

		> .inner-cell {
			display: table-cell;

			> .constraint {
				.width-limit();
			}
		}
	}
}

#header,
#strip {
	height: 1px;
}

#page {
	height: auto;

	.constraint {
		min-height: 100%;
	}
}

#footer {
	height: 1px;
}

.wf-mask() {
	.wf-loading & {
		visibility: hidden;
	}
}

.content {
	.typography();
}