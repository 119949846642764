.sidebar-widget {
	margin: (@gutter * 2);

	+ .sidebar-widget {
		margin-top: (@gutter * 4);
	}
}

.sidebar-table-of-contents-widget {
	margin-right: 0;
	padding-right: @gutter * 2;
	margin-top: (@gutter * 4);
	border-top: 1px solid;

	.table-of-contents-list {
		margin: (@gutter * 2) 0 (@gutter * 2) 0; 
		padding: @gutter 0;
		list-style: none;
		font-size: 14px;
		line-height: (4 / 3);
		letter-spacing: 0.15em;
		text-transform: uppercase;

		li {
			margin: 0;

			a {
				border-bottom: 1px solid transparent;
				transition: border-color 500ms @ease-out-expo;

				&:hover {
					transition: border-color 250ms @ease-out-expo;
				}
			}

			+ li {
				margin-top: unit((4 / 3), em);
			}
		}
	}
}

@media screen and (min-width: @screen-min-medium) {
	.sidebar-table-of-contents-widget {
		margin-right: -(@gutter * 2); // outdent right
	}
}

.sidebar-carousel-widget {
	.item-list {
		position: relative;
		margin: 0;
		padding: 100% 0 0 0;
		list-style: none;

		> li {
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			height: 100%;
			visibility: hidden;
			opacity: 0;

			a {
				display: block;
			}

			&.active {
				visibility: visible;
				z-index: 1;
			}

			&.active-in {
				z-index: 2;
			}

			&.fade {
				transition: opacity 1000ms @ease-out-expo;
			}

			&.in {
				opacity: 1;
			}
		}
	}

	img {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		max-width: 100%;
		max-height: 100%;
		width: auto;
		height: auto;
	}
}

.sidebar-advert-widget {
	text-align: center;

	img {
		display: inline-block;
		max-width: 100%;
	}
}

.sidebar-social-embed-widget {
	> .header {
		position: relative;
		margin: -1px -1px 0 -1px;
		height: (@gutter * 8);

		&:before {
			position: absolute;
			display: block;
			content: "";
			top: 50%;
			left: (@gutter * 2);
			width: 32px;
			height: 32px;
			margin-top: -16px;
			background-repeat: no-repeat;
			background-position: 0 0;
			background-size: 100% 100%;
		}

		.title {
			.visuallyhidden();
		}

		.action-wrapper {
			display: table;
			width: 100%;
			height: 100%;

			> .inner {
				display: table-cell;
				vertical-align: middle;
				text-align: right;
				padding: 0 (@gutter * 2);
			}
		}
	}

	> .embed {
		iframe {
			display: block;
			margin-left: auto;
			margin-right: auto;
			width: 100% !important;
		}
	}

	&.sidebar-twitter-embed-widget {

		a.twitter-timeline,
		a.twitter-follow-button {
			visibility: hidden;
		}

		> .header {
			background-color: @twitter;

			&:before {
				background-image: url("@{image-base-url}icon-tw.svg");
			}
		}

		> .embed {
			padding-top: @gutter;
		}
	}

	&.sidebar-facebook-embed-widget {
		> .header {
			background-color: @facebook;

			&:before {
				background-image: url("@{image-base-url}icon-fb.svg");
			}
		}
	}
	
	&.sidebar-linkedin-embed-widget {
		> .header {
			background-color: @linkedin;
			display: block;
			
			&:hover {
				background-color: darken(@linkedin, 10%);
			}

			&:before {
				background-image: url("@{image-base-url}icon-li.svg");
			}
			
			.action-wrapper > .inner {
			    text-align: left;
			    padding: 10px 16px 10px 64px;
			    font-size: 14px;
			    line-height: (18 / 14);
			}
			
			.linkedin-button {
				color: white;				
			}
		}
	}
}