.back-to-top{
	width: 50px;
	height: 50px;
	position: fixed;
	bottom: 0;
	right: 0;
	margin: 15px;
	z-index: 100;
	border-radius: 50%;
	background: #FFFFFF;
	box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.30);
	line-height: 50px;
	font-size: 25px;
	text-align: center;
	color: #6AA7B8;
	cursor: pointer;
}
