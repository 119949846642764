html {
	font-family: 'small';
}

@media screen and (min-width: @screen-min-medium) {
	html {
		font-family: "medium";
	}
}

@media screen and (min-width: @screen-min-large) {
	html {
		font-family: "large";
	}
}

// http://msdn.microsoft.com/en-us/library/ie/hh869615(v=vs.85).aspx
@-ms-viewport {
	width: device-width;
}