.chunk {
	margin-top: (@gutter * 4);
	margin-bottom: (@gutter * 4);

	+ .chunk {
		clear: both;
		margin-top: (@gutter * 8);
	}

	.chunk-header {
		margin-top: (@gutter * 4);
		margin-bottom: (@gutter * 2);

		&:first-child {
			margin-top: 0;
		}

		.chunk-title {
			margin: 0;
			font-size: 32px;
		}
	}

	.chunk-hero {
		position: relative;
		padding: 0 (@gutter * 2);

		> .hero-inner {
			position: relative;
			margin: (@gutter * 2) 0;
		}

		// IE8
		html.oldie & {
			border-bottom: 1px solid transparent;
		}
	}

	> .chunk-hero:first-child {
		.hero-inner {
			margin-top: -(@gutter * 2);
		}
	}

	.chunk-header {
		padding: 0 (@gutter * 2);
	}

	.chunk-body {
		box-sizing: border-box;
		margin: (@gutter * 2) 0 (@gutter * 6);
		padding: 0 (@gutter * 2);
	}
}

.chunk-map {
	.chunk-hero {
		> .hero-inner {
			padding-top: 50%;
		}
	}
}

.chunk-gallery {
	.chunk-hero {
		> .hero-inner {
			padding-top: (2 / 3 * 100%);
		}
	}

	// owl-carousel shenanigans
	.owl-item img {
		html.oldie & {
			width: auto;
			max-width: 100%;
		}
	}

	.owl-nav {
		position: absolute;
		top: 0;
		left: (-@gutter * 2);
		bottom: 0;
		right: (-@gutter * 2);
		visibility: hidden; // Hide positioning container to allow click events through to underlying carousel

		> div {
			overflow: hidden;
			position: absolute;
			top: 50%;
			width: 64px;
			height: 64px;
			margin: -32px 0 0 0;
			border-radius: 100%;
			text-align: center;
			line-height: 64px;
			visibility: visible; // Show nav items
		}

		> .owl-prev {
			left: 0;
			margin-left: -16px;
		}

		> .owl-next {
			right: 0;
			margin-right: -16px;
		}

		.no-csstransforms & {
			> div {
				font-size: 32px;
			}
		}

		.csstransforms & {
			> div {
				&:before {
					position: relative;
					box-sizing: border-box;
					content: "";
					display: block;
					width: (1 / 3 * 100%);
					height: (1 / 3 * 100%);
					top: (1 / 3 * 100%);
				}
			}

			> .owl-prev {
				&:before {
					left: 40%;
					border-left: 1px solid;
					border-bottom: 1px solid;
					transform: rotate(45deg);
				}
			}

			> .owl-next {
				&:before {
					left: 27.5%;
					border-right: 1px solid;
					border-bottom: 1px solid;
					transform: rotate(-45deg);
				}
			}
		}
	}

	.gallery-thumbs {
		position: relative;
		margin: (@gutter * 2) 0;
		padding-top: ((2 / 3 * 100%) / 3);

		html.oldie & {
			// Hack to force layout
			border-bottom: 1px solid transparent;
		}

		.owl-item {
			&:after {
				display: block;
				content: "";
				top: 100%;
				left: 0;
				right: 0;
				height: @gutter;
				margin-top: 1px;
				transition: background-color 500ms @ease-out-expo;
			}

			&.active {
				&.center {
					&:after {
						transition: background-color 250ms @ease-out-expo;
					}
				}
			}
		}
	}

	.gallery-cta {
		margin: (@gutter * 2) 0;
		text-align: center;
		font-size: 18px;

		a {
			border-bottom: 1px solid;
			transition: color 500ms @ease-out-expo, border-color 500ms @ease-out-expo;

			&:hover {
				transition: color 250ms @ease-out-expo, border-color 250ms @ease-out-expo;
			}
		}
	}
}

.chunk-adjacent-media {
	.chunk-media {
		text-align: center;
		padding-left: (@gutter * 2);
		padding-right: (@gutter * 2);

		img {
			margin-left: auto;
			margin-right: auto;
			max-width: 100%;
			height: auto;
		}
	}
}

@media screen and (max-width: @screen-max-small) {
	.chunk-adjacent-media {
		.chunk-media {
			margin-top: (@gutter * 3);
			margin-bottom: (@gutter * 3);
		}
	}
}

@media screen and (max-width: @screen-max-medium) {
	.chunk-gallery {
		.owl-nav {
			overflow: hidden;

			> .owl-prev {
				margin-left: -8px;
			}

			> .owl-next {
				margin-right: -8px;
			}
		}
	}
}

@media screen and (min-width: @screen-min-medium) {
	.chunk-adjacent-media {
		.chunk-media {
			float: right;
			padding-top: (@gutter * 2);
			padding-bottom: (@gutter * 4);
		}
	}
}

@media screen and (min-width: @screen-min-large) {
	.chunk-map {
		.chunk-hero {
			> .hero-inner {
				padding-top: (3 / 8 * 100%);
			}
		}
	}

	.chunk-gallery {
		.gallery-thumbs {
			padding-top: ((2 / 3 * 100%) / 5);
		}
	}

	.chunk-adjacent-media {
		.chunk-media {
		}

		.chunk-body {
		}
	}
}