#strip {
	.constraint {
		box-sizing: border-box;
		margin-bottom: (@gutter * 2);
		padding: (@gutter * 2) @gutter;
		text-align: center;
		font-size: 18px;
		line-height: 1.25;

		html.oldie & {
			font-size: 17px;
		}

		a {
			border-bottom: 1px solid;
			transition: color 500ms @ease-out-expo, border-color 500ms @ease-out-expo;

			&:hover {
				transition: color 250ms @ease-out-expo, border-color 250ms @ease-out-expo;
			}
		}

		p {
			margin: 0;

			+ p {
				margin-top: 0.75em;
			}
		}
	}
}