.alert {
	box-sizing: border-box;
	margin: 1.5em -@gutter;
	padding: 1em (@gutter * 2);
	border-radius: 4px;

	.alert-title {
		margin: 0;
		font-size: 21px;
		line-height: (4 / 3);

		+ .content {
			margin-top: 1em;
		}
	}

	.content {
		margin-bottom: -1em;
		font-size: 85%;
	}
}