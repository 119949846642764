.btn {
	box-sizing: border-box;
	display: inline-block;
	margin: 0;
	padding: 0;
	line-height: 1.25em;
	border: 0;
}

.btn-regular {
	display: inline-block;
	padding: ((2em - 1.25em) / 2) 0.5em; // .form-control height (@2em / .btn-height)
	vertical-align: middle;
}