.page-article {
	.clearfix();

	.article-header,
	.article-body,
	.article-sidebar {
		box-sizing: border-box;
	}

	.article-header {
		margin-top: (@gutter * 6);
		margin-bottom: (@gutter * 2);
		padding-left: (@gutter * 2);
		padding-right: (@gutter * 2);

		.article-title {
			margin: 0;
		}
	}

	.article-sidebar {
		margin-bottom: (@gutter * 4); 
	}
}

@media screen and (max-width: @screen-max-small) {
	.page-article {
		.article-sidebar {
			margin-bottom: (@gutter * 6);
		}
	}
}

@media screen and (min-width: @screen-min-medium) {
	.page-article {
		.article-header {
			margin-left: (1 / 16 * 100%);
			margin-right: (1.5 / 16 * 100%);
		}

		.article-body {
			padding-left: (1 / 16 * 100%);
			padding-right: (1.25 / 16 * 100%);

			.article-body-outdent {
				margin-left: -(1 / 13.5 * 100%);
				margin-right: -(1.25 / 13.5 * 100%);
			}

			.article-adjacent-outdent {
				margin-right: 0;
				width: (4.5 / 13.5 * 100%);
			}
		}

		.article-sidebar {
			float: right;
			width: (6 / 16 * 100%);
			padding-right: (@gutter * 2)
		}

		&.has-sidebar {
			.article-header {
				margin-left: (0.5 / 16 * 100%);
				margin-right: (1 / 16 * 100%);
			}

			.article-body {
				float: left;
				width: (10 / 16 * 100%);
				padding-left: (0.5 / 16 * 100%);
				padding-right: (0.75 / 16 * 100%);

				.article-body-outdent {
					margin-left: -(0.5 / 8.75 * 100%);
					margin-right: -(0.75 / 8.75 * 100%);
				}

				.article-adjacent-outdent {
					margin-right: -(0.25 / 8.75 * 100%);
					width: (2.75 / 8.75 * 100%);
				}
			}
		}
	}
}

@media screen and (min-width: @screen-min-large) {
	.page-article {
		.article-header {
			//margin-left: (2 / 16 * 100%);
			//margin-right: (3 / 16 * 100%);
		}

		.article-body {
			//padding-left: (2 / 16 * 100%);
			//padding-right: (3 / 16 * 100%);

			.article-body-outdent {
				//margin-left: -(2 / 11 * 100%);
				//margin-right: -(3 / 11 * 100%);
			}
		}

		.article-sidebar {
			width: (5 / 16 * 100%);
		}

		&.has-sidebar {
			.article-header {
				margin-left: (1 / 16 * 100%);
				margin-right: (1 / 16 * 100%);
			}

			.article-body {
				width: (11 / 16 * 100%);
				padding-left: (1 / 16 * 100%);
				padding-right: (1 / 16 * 100%);
				//background-color: yellow;

				.article-body-outdent {
					margin-left: -(1 / 9 * 100%);
					margin-right: -(1 / 9 * 100%);
					//background-color: green;
				}

				.article-adjacent-outdent {
					margin-right: -(0.75 / 9 * 100%);
					width: (3.25 / 9 * 100%);
				}
			}
		}
	}
}